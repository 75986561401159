import axios from "axios";
import _ from "lodash";
import cookies from "next-cookies";

const getToken = () => {
  return cookies("token")?.token ?? "";
};

const instance = axios.create({
  baseURL:
    process.env.NEXT_PUBLIC_API + process.env.NEXT_PUBLIC_API_VERSION + "/",
});

export const getArticles = (params) => {
  const req = instance.get(`/posts`, {
    params: _.pickBy(params, _.identify),
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const getArticleById = (id) => {
  const req = instance.get(`/posts/${id}`, {
    params: {},
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const getRequestSupportById = (id, token) => {
  const req = instance.get(`/requests/${id}`, {
    params: {},
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
