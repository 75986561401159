import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Chip, Typography } from "@mui/material";
import MapLocation from "common/Icons/MapLocation";
import { findType } from "common/User";
import {
  filterSubCate,
  findCateTopic,
  findTypeTopic,
} from "components/Common/Topic";
import Langs from "components/Const/Lang";
import useWidthScreen from "components/Hook/GetWidthScreen";
import LimitRowContent from "components/LimitRowContent";
import moment from "moment-mini";
import numeral from "numeral";
import React from "react";

const defaultImg =
  process.env.NEXT_PUBLIC_MEDIA_API + process.env.NEXT_PUBLIC_DEFAULT_THUMB;

const ArticleSubMenu = ({
  isCommunity,
  data,
  img,
  title,
  area,
  createdAt,
  type,
  categoryId,
  totalBeingFollowed,
  lang = "vi",
  size,
}) => {
  let imageSize = useWidthScreen();
  return (
    <>
      {isCommunity ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "#fff",
          }}
          my={1}
          pr={0}
        >
          <img
            src={
              img?.length > 0
                ? process.env.NEXT_PUBLIC_MEDIA_API + img + `?size=${imageSize}`
                : defaultImg
            }
            alt="article sub menu"
            className="img-article-submenu"
            style={{
              minWidth: "100%",
              minHeight: "155px",
              boxShadow: "0 0 3px 3px #ddd",
            }}
          />
          <Box sx={{ width: "100%", paddingX: "10px" }}>
            <LimitRowContent
              row={2}
              mt={1}
              lineHeight={1.5}
              fontSize={16}
              variant="h6"
            >
              <strong>{title}</strong>
            </LimitRowContent>

            <Chip
              size="small"
              label={findType(
                type,
                lang ?? process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE
              )}
              sx={{
                height: "20px",
                fontSize: "0.725rem",
                backgroundColor: findType(type, "color"),
                borderRadius: "4px",
              }}
            />

            <Typography
              sx={{ mb: 0, mt: 1 }}
              variant="caption"
              display="block"
              gutterBottom
            >
              {/* <LocationOnIcon sx={{ fontSize: "12px", marginRight: "5px" }} /> */}
              <MapLocation
                style={{
                  color: "#f19e9e",
                  fontSize: "14px",
                  marginRight: "2px",
                }}
              />
              {area?.length > 0
                ? area?.replace("Tỉnh", "")?.replace("Thành phố", "")
                : ` ${Langs?.chua_co_thong_tin?.[lang]}`}
            </Typography>

            <Box sx={{ display: "flex" }}>
              <FavoriteIcon
                style={{ color: "#f19e9e", fontSize: "16px", marginTop: "2px" }}
              />
              <p style={{ margin: "0 2px" }}>
                {totalBeingFollowed
                  ? numeral(totalBeingFollowed).format("0,0")
                  : 0}
              </p>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            direction: "columns",
            justifyContent: "flex-start",
            width: size === "small" ? "250px !important" : "100%",
          }}
          my={2}
          className="box-article-submenu"
        >
          <img
            src={
              img?.length > 0
                ? process.env.NEXT_PUBLIC_MEDIA_API + img
                : defaultImg
            }
            alt="article sub menu"
            className={
              size === "small"
                ? "small-img-article-submenu"
                : "img-article-submenu"
            }
          />
          <Box sx={{ width: "100%" }}>
            <LimitRowContent
              row={2}
              mt={size === "small" ? 0 : 1}
              // lineHeight={1.5}
              fontSize={size === "small" ? 12 : 16}
              variant="h6"
              minHeight={size === "small" ? "40px" : null}
            >
              <strong>{title}</strong>
            </LimitRowContent>
            {categoryId ? (
              <Chip
                size="small"
                label={
                  filterSubCate(
                    findTypeTopic(categoryId, "subCate"),
                    "type",
                    type
                  )?.[lang] ?? ""
                }
                sx={{
                  margin: "5px 0",
                  height: size === "small" ? "15px" : "20px",
                  fontSize: size === "small" ? "12px" : "0.725rem",
                  backgroundColor: findCateTopic(type, "color"),
                  borderRadius: "4px",
                }}
              />
            ) : (
              <Chip
                size="small"
                label={findType(
                  type,
                  lang ?? process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE
                )}
                sx={{
                  height: size === "small" ? "15px" : "20px",
                  fontSize: size === "small" ? "12px" : "0.725rem",
                  backgroundColor: findType(type, "color"),
                  borderRadius: "4px",
                }}
              />
            )}
            <Box sx={{ display: "flex" }} justifyContent={"space-between"}>
              <Box>
                <Typography
                  sx={{ mb: 0, fontSize: "10px" }}
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  {(createdAt &&
                    moment(createdAt).format("HH:mm DD/MM/YYYY")) ||
                    ""}{" "}
                  {area
                    ? "-" + area?.replace("Tỉnh", "")?.replace("Thành phố", "")
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ArticleSubMenu;
