import { Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Langs from "components/Const/Lang";
import moment from "moment-mini";
import cookies from "next-cookies";
import React, { useState } from "react";

const NotiItem = ({ title, content, createdAt, unread, ...rest }) => {
  const [shadow, setShadow] = useState(rest?.shadow ?? 1);

  const onMouseOver = () => setShadow(4);
  const onMouseOut = () => setShadow(1);
  return (
    <Box
      elevation={shadow}
      sx={{ cursor: "pointer" }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        sx={
          shadow !== 1
            ? { backgroundColor: "#fff", boxShadow: "3px 3px 3px #ddd" }
            : {}
        }
      >
        {unread === 1 && ( //chua doc
          <div
            style={{
              width: "5px",
              backgroundColor: "#f47376",
            }}
          />
        )}
        <Box
          py={2}
          sx={{
            marginLeft: "15px",
            borderBottom: "1px solid #ddd",
            width: "100%",
          }}
          pr={1}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          <Typography variant="body1" my={1}>
            {content}
          </Typography>
          <Typography variant="caption" sx={{ color: "#888" }}>
            {createdAt ? moment(createdAt).format("DD/MM/YYYY HH:mm") : ""}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default NotiItem;
