import { makeStyles } from "@material-ui/core";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Article } from "components";
import ArticleBanner from "components/Article/ArticleBanner";
import ArticleFooter from "components/Article/ArticleFooter";
import ArticleHeader from "components/Article/ArticleHeader";
import {
  findTypeTopic,
  STATUS_ACTIVE,
  STATUS_CLOSED,
  STATUS_INACTIVE,
} from "components/Common/Topic";
import Langs from "components/Const/Lang";
import LimitRowContent from "components/LimitRowContent";
import cookies from "next-cookies";
import Link from "next/link";
import { useRouter } from "next/router";
import { putStatusTopic } from "pages/api";
import React, { useState } from "react";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#64B5EC",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#64B5EC",
    },
  },
});

const UserArticle = ({
  title,
  content,
  area,
  date,
  type,
  creatorType,
  avatarUrl,
  name,
  banner,
  srcset,
  bannerAlt,
  categoryId,
  id,
  status,
  setObjAlert,
  changeTab,
  isAvailable,
}) => {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const auth = cookies("auth")?.auth ?? {};
  let lang = cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;
  const classes = useStyles();

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pauseTopic = () => {
    setLoading(true);
    putStatusTopic({
      id,
      values: {
        status:
          status === STATUS_INACTIVE?.id
            ? STATUS_ACTIVE?.id
            : STATUS_INACTIVE?.id,
      },
    })
      .then((req) => {
        changeTab(status === STATUS_ACTIVE?.id ? 1 : 0);
        setObjAlert({
          type: "success",
          text: Langs?.cap_nhat_trang_thai_bai_viet_thanh_cong?.[lang],
        });
        setLoading(false);
      })
      .catch((err) => {
        setObjAlert({
          type: "error",
          text: Langs?.cap_nhat_trang_thai_bai_viet_that_bai?.[lang],
        });
        setLoading(false);
      });
    handleClose();
  };

  return (
    <Article paperSx={{ padding: "12px 0", border: "1px solid #ddd" }}>
      <Box px={3}>
        <ArticleHeader
          type={type}
          avatarUrl={avatarUrl}
          name={name}
          categoryId={categoryId}
          creatorType={creatorType}
        >
          {auth?.id * 1 === router?.query?.slug * 1 && isAvailable === 1 && (
            <IconButton>
              <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                onClick={handleClickMenu}
                sx={{ minWidth: "auto", padding: 0, border: 0 }}
              >
                <MoreHorizSharpIcon align="right" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => router.push(`/user/post/${id}`)}>
                  {Langs?.chinh_sua_tin?.[lang]}
                </MenuItem>
                {status !== STATUS_CLOSED?.id && (
                  <MenuItem onClick={handleClickOpen}>
                    {status === STATUS_ACTIVE?.id
                      ? Langs?.tam_dung_bai_viet?.[lang]
                      : Langs?.kich_hoat_bai_viet?.[lang]}
                  </MenuItem>
                )}
              </Menu>
            </IconButton>
          )}
        </ArticleHeader>
        <Link key={id + ""} href={`${findTypeTopic(categoryId, "link")}/${id}`}>
          <Box>
            <ArticleBanner
              banner={banner}
              srcset={srcset}
              bannerAlt={bannerAlt}
            />
            <LimitRowContent
              mt={1}
              mb={2}
              fontSize={18}
              variant="h6"
              lineHeight={1.2}
              minHeight="56px"
            >
              {title}
            </LimitRowContent>
            <LimitRowContent
              mb={0}
              className="article-content"
              paragraph
              variant="caption"
              minHeight="30px"
            >
              {content}
            </LimitRowContent>
            <ArticleFooter date={date} area={area} />
          </Box>
        </Link>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {status === STATUS_ACTIVE?.id
            ? Langs?.ban_muon_dung_tin?.[lang]
            : Langs?.ban_muon_kich_hoat_tin?.[lang]}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "#888" }}>
            {Langs?.huy_bo?.[lang]}
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            // sx={{ backgroundColor: "#64B5EC", color: "#fff" }}
            onClick={pauseTopic}
            autoFocus
            className={classes.button}
          >
            {Langs?.dong_y?.[lang]}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Article>
  );
};

export default UserArticle;
