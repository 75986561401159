const { createSvgIcon } = require("@mui/material");

const MapLocation = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <title />
    <g data-name="Layer 2" id="Layer_2">
      <g id="Interface-Solid">
        <g id="interface-solid-pin-location">
          <circle cx="15" cy="9.57129" fill="none" r="3.42865" />
          <path d="M25.31152,20.40039a1,1,0,1,0-.623,1.90039C26.97607,23.05078,28,23.94629,28,24.5c0,1.39355-5.18506,3.5-13,3.5S2,25.89355,2,24.5c0-.55371,1.02393-1.44922,3.31152-2.19922a1,1,0,1,0-.623-1.90039C.81348,21.6709,0,23.33105,0,24.5,0,28.11035,7.5459,30,15,30s15-1.88965,15-5.5C30,23.33105,29.18652,21.6709,25.31152,20.40039Z" />
          <path d="M14.37549,25.78125a1.00083,1.00083,0,0,0,1.249,0c.36572-.293,8.94678-7.26074,8.94678-16.21a9.57129,9.57129,0,0,0-19.14258,0C5.42871,18.52051,14.00977,25.48828,14.37549,25.78125Zm-2.80408-16.21A3.42859,3.42859,0,1,1,15,13,3.4287,3.4287,0,0,1,11.57141,9.57129Z" />
        </g>
      </g>
    </g>
  </svg>,
  "MapLocation"
);

export default MapLocation;
