import { useMediaQuery } from "@mui/material";

const useWidthScreen = () => {
  const lgScreen = useMediaQuery("(min-width:768px)");
  const xsScreen = useMediaQuery("(max-width:425px)");

  if (lgScreen) {
    return 1080;
  } else {
    if (xsScreen) {
      return 360;
    } else return 720;
  }
};

export default useWidthScreen;
