import { Grid, IconButton, Typography } from "@mui/material";
import { getArticles } from "actions/homeAPI";
import { getMembers } from "actions/memberAPI";
import { getNotifications, readOneNoti } from "actions/notificationAPI";
import Article from "components/Article";
import ArticleHeader from "components/Article/ArticleHeader";
import ArticleSubMenu from "components/ArticleSubMenu";
import { TYPE_ORGANIZATION } from "components/Common/User";
import Langs from "components/Const/Lang";
import ModalLoginAlert from "components/ModalLoginAlert";
import _ from "lodash";
import cookies from "next-cookies";
import { useRouter } from "next/router";
import { getFollowers } from "pages/api";
import NotiItem from "pages/notification/components/NotiItem";
import UserArticle from "pages/user/components/UserArticle";
import React, { useEffect, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import useWidthScreen from "./GetWidthScreen";
import useIntersectionObserver from "./useIntersectionObserver";
import { useInView } from "react-intersection-observer";
// import IconButton from "@mui/material/IconButton";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const defaultImg =
  process.env.NEXT_PUBLIC_MEDIA_API + process.env.NEXT_PUBLIC_DEFAULT_THUMB;

function LoadMore({
  url,
  link,
  isCommunity,
  isFollower,
  isNotification,
  paramsNoti,
  isMyPost,
  setObjAlert,
  changeTab,
}) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const token = cookies("token")?.token ?? "";
  const lang =
    cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;
  const auth = cookies("auth")?.auth || {};
  const scrollTo = cookies("scrollTo")?.scrollTo || {};
  const [visible, setVisible] = useState(false);
  const params = router.query;
  const [pageNoti, setPageNoti] = useState(1);
  let imageSize = useWidthScreen();
  const { ref, inView } = useInView();
  const [displayScrollTopBtn, setDisplayScrollTopBtn] = useState(false);

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    refetch,
  } = useInfiniteQuery(
    ["members", params],
    async ({ pageParam = 1 }) => {
      let _params = _.cloneDeep(params);
      if (pageParam) {
        if (isNotification) {
          _params.member_id = auth?.id;
          _params.limit = 10;
          setPageNoti(pageParam);
          if (pageParam) {
            let arrPages = data?.pages;
            let arr =
              arrPages?.[pageParam === 1 ? 0 : arrPages?.length - 1]?.data
                ?.data;
            _params.lastNotifyId =
              pageParam > 1 && arr?.[arr?.length - 1]?.notifyItemId;
            return await getNotifications({ ..._params, ...paramsNoti });
          }
        } else {
          _params.page = pageParam;
          _params.size = isFollower ? 24 : 12;
          if (url === "/members") {
            if (_params?.types) {
              return await getMembers(_params);
            }
          } else {
            if (url === "/followers") {
              return await getFollowers(_params);
            } else {
              return await getArticles(_params);
            }
          }
        }
      }
    },
    {
      getPreviousPageParam: (firstPage) => false,
      getNextPageParam: (lastPage) => {
        if (isNotification) {
          return pageNoti * 10 < lastPage?.data?.total ? pageNoti + 1 : false;
        } else {
          return lastPage?.data?.pageInfo?.page *
            lastPage?.data?.pageInfo?.size <
            lastPage?.data?.pageInfo?.total
            ? lastPage?.data?.pageInfo?.page + 1
            : false;
        }
      },
    }
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const loadMoreButtonRef = React.useRef();
  useIntersectionObserver({
    target: ref,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  useEffect(() => {
    if (
      scrollTo &&
      scrollTo?.categoryId * 1 === params?.categoryId * 1 &&
      scrollTo?.scrollTo > 0
    ) {
      window.scrollTo({ top: scrollTo?.scrollTo, behavior: "smooth" });
      document.cookie =
        "scrollTo={}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
    queryClient.setQueryData("members", (data) => {
      return {
        pages: [data],
        pageParams: [1],
      };
    });
    refetch({
      refetchPage: (page, index) => {
        return index === 0;
      },
    });
  }, [params]);

  const onClickDetail = (item) => {
    if (isNotification) {
      if (item?.category?.type * 1 === 5 || item?.category?.type * 1 === 7) {
        //5: bao cao vi pham tai khoan, 7: rating tha tim
        router.push({
          pathname: `/user/${item?.category?.value}`,
        });
      } else {
        router.push({
          pathname: `/notification/${item?.notifyItemId}`,
          query: {
            type: item?.category?.type,
            value:
              item?.category?.type * 1 === 1
                ? item?.notifyItemId
                : item?.category?.value,
            isExpired:
              item?.category?.type * 1 === 3
                ? item?.category?.isExpired || -1
                : undefined,
          },
        });
      }
    } else {
      router.push({
        pathname: `/${link}/${item?.id}`,
      });
    }
    readOneNoti(item?.notifyItemId, { member_id: auth?.id });
  };

  const renderItemCommunity = (item) => {
    return (
      <Grid
        item
        xs={6}
        sm={4}
        md={3}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          token?.length > 0
            ? router.push({
                pathname: `/user/${item.id}`,
              })
            : setVisible(true);
          // document.cookie = `scrollTo=${JSON.stringify({
          //   categoryId: params?.categoryId,
          //   scrollTo: window.pageYOffset,
          // })}; path=/; `;
        }}
        key={item?.id}
      >
        <div className="animate__animated animate__backInUp">
          <ArticleSubMenu
            totalBeingFollowed={item?.totalBeingFollowed}
            isCommunity={isCommunity}
            img={item?.images?.avatar}
            title={item?.name}
            area={item?.city?.name}
            createdAt={item?.createdAt}
            type={item?.type}
            categoryId={null}
            lang={lang}
          />
        </div>
      </Grid>
    );
  };

  const renderItemFollower = (item) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ cursor: "pointer" }}
        onClick={() =>
          router.push({
            pathname: `/user/${item?.id}`,
            // query: router.query && renderURL('', router.query)
          })
        }
        key={item?.id}
      >
        <div className="animate__animated animate__backInUp">
          <ArticleHeader
            type={item?.type}
            creatorId={item?.memberId}
            creatorType={item?.member?.type}
            avatarUrl={
              item?.member?.images?.avatar
                ? process.env.NEXT_PUBLIC_MEDIA_API +
                  item.member.images.avatar +
                  "?size=360"
                : defaultImg
            }
            avatarAlt={item?.member?.images?.altText}
            name={item?.member?.name}
            categoryIds={null}
            categoryId={null}
            notClickUser={true}
            {...item}
          />
        </div>
      </Grid>
    );
  };

  const renderItemTopic = (item) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          document.cookie = `backLink=${JSON.stringify(
            router.asPath
          )}; path=/; `;

          document.cookie = `scrollTo=${JSON.stringify({
            categoryId: params?.categoryId,
            scrollTo: window.pageYOffset,
          })}; path=/; `;

          router.push({
            pathname: `/${link}/${item.id}`,
          });
        }}
        key={item?.id}
      >
        <div className="animate__animated animate__backInUp">
          <Article
            area={item?.city?.name ?? "Toàn quốc"}
            type={item?.type}
            banner={
              item?.images?.image
                ? process.env.NEXT_PUBLIC_MEDIA_API +
                  item.images.image +
                  `?size=${imageSize}`
                : defaultImg
            }
            bannerAlt={item?.images?.altText ?? "Banner"}
            avatarUrl={
              item?.creator?.images?.avatar
                ? process.env.NEXT_PUBLIC_MEDIA_API +
                  item.creator.images.avatar +
                  "?size=360"
                : defaultImg
            }
            name={item?.creator?.name ?? "-"}
            title={item?.title ?? "-"}
            date={item?.createdAt}
            creatorId={item?.creator?.id}
            creatorType={item?.creator?.type}
            notClickUser={true}
          />
        </div>
      </Grid>
    );
  };

  const renderItemNotification = (item) => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ cursor: "pointer" }}
        onClick={() => onClickDetail(item)}
        key={item?.id}
      >
        <div className="animate__animated animate__backInUp">
          <NotiItem
            title={item?.meta?.[lang]?.title || item?.title}
            content={item?.meta?.[lang]?.content || item?.content}
            createdAt={item?.createdAt}
            unread={item?.unread}
          />
        </div>
      </Grid>
    );
  };

  const renderItemMyPost = (item) => {
    return (
      <Grid item xs={12} md={6} lg={6} key={item?.id}>
        <div className="animate__animated animate__backInUp">
          <UserArticle
            id={item?.id}
            area={item?.city?.name ?? "Toàn quốc"}
            type={item?.type}
            banner={
              item?.images?.image
                ? process.env.NEXT_PUBLIC_MEDIA_API +
                  item.images.image +
                  "?size=360"
                : defaultImg
            }
            bannerAlt={item?.images?.altText || "Banner"}
            avatarUrl={
              item?.creator?.images?.avatar
                ? process.env.NEXT_PUBLIC_MEDIA_API +
                  item.creator.images.avatar +
                  "?size=360"
                : defaultImg
            }
            name={item?.creator?.name ?? "-"}
            title={item?.title ?? ""}
            content={item?.content ?? ""}
            date={item?.createdAt}
            categoryId={item?.categoryId}
            setObjAlert={setObjAlert}
            status={item?.status}
            changeTab={changeTab}
            creatorType={item?.creator?.type}
            isAvailable={item?.isAvailable}
          />
        </div>
      </Grid>
    );
  };

  return (
    <div>
      {status === "loading" ? (
        <p>Loading...</p>
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
        <>
          {!data?.pages?.[0] || !data?.pages?.[0]?.data?.data ? (
            <Typography align="center" sx={{ marginTop: "30px" }} variant="h6">
              {Langs?.chua_co_tin_moi?.[lang]}
            </Typography>
          ) : data?.pages?.[0]?.data?.data?.length === 0 ? (
            <Typography align="center" sx={{ marginTop: "30px" }} variant="h6">
              {Langs?.khong_tim_thay_ket_qua?.[lang]}
            </Typography>
          ) : (
            <>
              {isCommunity && (
                <Typography variant="h6">
                  {router.query?.types &&
                  JSON.parse(router.query?.types)?.length === 1
                    ? JSON.parse(router.query?.types)?.includes(
                        TYPE_ORGANIZATION?.id
                      )
                      ? Langs?.danh_sach_to_chuc?.[lang]
                      : Langs?.danh_sach_manh_thuong_quan?.[lang]
                    : Langs?.danh_sach_cong_dong?.[lang]}{" "}
                  ({data?.pages?.[0]?.data?.pageInfo?.total})
                </Typography>
              )}

              {isFollower && (
                <>
                  <Typography variant="h6">
                    {Langs?.danh_sach_yeu_thuong?.[lang]}{" "}
                    {data?.pages?.[0]?.data?.pageInfo?.total > 0 &&
                      `(${data?.pages?.[0]?.data?.pageInfo?.total})`}
                  </Typography>
                  <Typography sx={{ marginBottom: "20px" }} variant="body2">
                    {Langs?.xem_ngay_nhung_tai_khoan?.[lang]}{" "}
                    {router?.query?.name}
                  </Typography>
                </>
              )}

              <Grid container spacing={1}>
                {data?.pages?.map((page) => (
                  <React.Fragment key={page?.nextId}>
                    {page?.data?.data?.map((item) => {
                      if (isCommunity) {
                        return renderItemCommunity(item);
                      }

                      if (isFollower) {
                        return renderItemFollower(item);
                      }

                      if (isNotification) {
                        return renderItemNotification(item);
                      }

                      if (isMyPost) {
                        return renderItemMyPost(item);
                      }

                      return renderItemTopic(item);
                    })}
                  </React.Fragment>
                ))}
              </Grid>
            </>
          )}

          <div style={{ textAlign: "center", padding: "10px 0" }}>
            <button
              ref={ref}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              {isFetchingNextPage
                ? "Loading more..."
                : hasNextPage
                ? "Loading more..."
                : Langs?.cuoi_danh_sach?.[lang]}
            </button>
          </div>
          <div>
            {isFetching && !isFetchingNextPage
              ? "Background Updating..."
              : null}
          </div>
        </>
      )}

      <ModalLoginAlert
        visible={visible}
        setVisible={(vis) => setVisible(vis)}
        lang={lang}
      />
    </div>
  );
}

export default React.memo(LoadMore);
